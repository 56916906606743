<template lang="pug">
  div
    .sidebar-layout__toolbar
      button.btn.btn--brand(
        v-permission-hide="permissions.create_silage"
        @click="modals.showCreateNew = true")
        font-awesome-icon.mr-1(
          size="sm"
          icon="plus")
        span {{ isMobile ? 'actions.create_mobile' : 'actions.add_silage' | translate }}

      v-dialog(
        max-width="348"
        v-model="modals.showCreateNew"
      )
        create-new-modal(
          @close="modals.showCreateNew = false")

    navigation
    //- FOR DESKTOP AND TABLETS
    template(v-if="!isMobile")
      silage-table

    //- FOR MOBILE ONLY
    template(v-if="isMobile")
      silage-table-mobile

</template>

<script>
  import { mapGetters } from 'vuex'

  import adaptationMixin from '@/mixins/adaptation.mixin'

  import Navigation from '../common/Navigation'

  import FilterModal from './modals/FilterModal'
  import CreateNewModal from './modals/CreateNewModal'
  import permissions from '@/util/permissions'

  const SilageTable = () => import('./SilageTable')
  const SilageTableMobile = () => import('./SilageTableMobile')

  export default {
    name: 'SettingsSilage',

    mixins: [adaptationMixin],

    components: {
      Navigation,
      SilageTable,
      SilageTableMobile,
      FilterModal,
      CreateNewModal
    },

    data: () => ({
      modals: {
        showFilters: false,
        showCreateNew: false
      },
      permissions: permissions
    }),

    computed: {
    ...mapGetters([
      'hasSilagesFilters'
    ])
    }
  }
</script>

<style lang="scss" scoped>
</style>
